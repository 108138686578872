<template>
  <section :class="[$style.faq, $style[$mq]]">
    <h4>获取帮助</h4>
    <mq-layout :mq="['xs', 'sm']">
      <ul>
        <li v-for="([path, item], index) in questions" :key="index">
          <div @click="$router.push(`${$route.path}/${path}`)">
            <span>{{ item }}</span>
            <icon-base v-bind="optArrowIcon"><icon-arrowright /></icon-base>
          </div>
        </li>
      </ul>
    </mq-layout>
    <mq-layout mq="md+" :class="$style.container">
      <ul v-for="(list, index1) in arrQ" :key="index1">
        <li v-for="([path, item], index2) in list" :key="index2">
          <div @click="$router.push(`${$route.path}/${path}`)">
            <span>{{ item }}</span>
            <icon-base v-bind="optArrowIcon"><icon-arrowright /></icon-base>
          </div>
        </li>
      </ul>
    </mq-layout>
  </section>
</template>

<script>
import debug from "debug";
import IconBase from "@/components/IconBase";
import IconArrowright from "@/components/icons/IconArrowright";

const log = debug("koiweb");

export default {
  name: "faq_zh",
  data() {
    return {
      questions: [
        ["q01", "为什么要选择 KOI TRADING?"],
        ["q02", "在 KOI TRADING 上交易需要什么资质？"],
        ["q03", "如何开户？"],
        ["q04", "为什么在开户时要提供各种证明文件？"],
        ["q05", "交易流程？"],
        ["q06", "KOI 的价格好吗？"],
        ["q07", "支持哪些数字货币？"],
        ["q08", "支持哪些法币？"],
        ["q09", "最小交易量"],
        ["q10", "最大交易量"],
        ["q11", "结算流程？"],
        ["q12", "银行汇款多久到账？"],
        ["q13", "能否帮忙报税？"],
        ["q14", "如何联系 KOI TRADING 团队？"]
      ],
      optArrowIcon: {
        width: "16",
        height: "16",
        iconName: "arrowright"
      }
    };
  },
  props: {},
  computed: {
    arrQ() {
      const index = Math.ceil(this.questions.length / 2);
      return [this.questions.slice(0, index), this.questions.slice(index)];
    }
  },
  components: {
    IconBase,
    IconArrowright
  },
  mounted() {
    log("component (faq_zh) mounted");
  }
};
</script>

<style module>
.faq {
  font-family: SimSun;
  padding: 4vw 8vw 4vw;
  text-align: left;
  & h4 {
    font-size: 1.6rem;
    font-weight: 400;
  }
  & .container {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    & ul {
      &:after {
        border-left: 1px solid #fff;
        content: " ";
        display: block;
        position: absolute;
        height: 1rem;
        left: 0;
        width: 0;
      }
      &:first-child:after {
        bottom: 1rem;
      }
      &:last-child:after {
        top: 1rem;
      }
      &:first-child:before {
        bottom: 0;
        margin-bottom: 0;
        top: 2.8rem;
      }
      &:last-child:before {
        bottom: 2.8rem;
        margin-bottom: 4vw;
        top: 0;
      }
    }
  }
  & ul {
    align-items: center;
    align-content: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -8vw 0 0;
    padding: 0 0 4vw 0;
    position: relative;
    &:before {
      border-left: 1px solid #c8c8c8;
      bottom: 2.8rem;
      content: " ";
      display: block;
      position: absolute;
      left: 0;
      margin-bottom: 4vw;
      top: 2.8rem;
      width: 0;
    }
  }
  & li {
    cursor: pointer;
    font-size: 1rem;
    line-height: 2;
    list-style: none;
    margin: 0.8rem 8vw 0.8rem 2vw;
    position: relative;
    width: 37vw;
    &:before {
      border: 2.5px solid #000;
      content: " ";
      display: block;
      left: -2px;
      position: absolute;
      top: 50%;
      transform: translate(-2vw, -50%);
    }
    & div {
      align-items: center;
      border-bottom: 1px solid #c6c6c6;
      display: flex;
      justify-content: space-between;
      margin: 0 0 0 1rem;
      padding: 1rem 1rem 1rem 0;
      transition: all 0.3s ease;
    }
    & span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &:hover div {
      border-bottom-color: #e50012;
      color: #e50012;
      padding-right: 0.2rem;
    }
  }
  &.xs,
  &.sm {
    & ul {
      margin-right: 0;
    }
    & li {
      margin-right: 0;
      width: 82vw;
    }
  }
  &.md {
  }
  &.lg {
  }
  &.xl {
  }
}
</style>
